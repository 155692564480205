var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100vw","height":"100vh","position":"relative"}},[_c('div',{staticStyle:{"width":"100%","position":"absolute"}},[_c('p',{staticClass:"project-code-style"},[_vm._v(_vm._s(_vm.select_project_name))]),_c('div',{staticStyle:{"width":"100%","text-align":"center","margin-top":"20px"}},[_c('p',{staticClass:"logout",on:{"click":_vm.clickExchangeProject}},[_vm._v("切换项目")]),_c('p',{staticClass:"logout",staticStyle:{"margin-left":"20px"},on:{"click":_vm.doLogout}},[_vm._v("退出登录")])]),_c('div',{staticClass:"list-container"},_vm._l((_vm.formatList),function(item,index){return _c('div',{key:item._id.$id},[_c('div',{staticClass:"article-item",on:{"click":function($event){$event.stopPropagation();return _vm.goDetail(item)}}},[_c('div',{staticClass:"article-title"},[_vm._v(_vm._s(item.name))]),_c('img',{class:{
          rightIcon: item.children.length === 0,
          rotateIcon: item.children.length !== 0 && item.isShow,
        },attrs:{"src":item.children.length > 0
            ? require('../assets/arrow.png')
            : require('../assets/go.png')}})]),_c('Transition',{attrs:{"name":"slide-fade"}},[(item.children.length > 0 && item.isShow)?_c('div',[_c('div',_vm._l((item.children),function(subItem){return _c('div',{key:subItem._id.$id,on:{"click":function($event){$event.stopPropagation();return _vm.goDetail(subItem)}}},[_c('div',{staticClass:"sub-article-item"},[_c('div',{staticClass:"sub-article-title"},[_vm._v(_vm._s(subItem.name))]),_c('img',{class:{
                  rightIcon: subItem.children.length === 0,
                  rotateIcon: subItem.children.length !== 0 && subItem.isShow,
                },attrs:{"src":subItem.children.length > 0
                    ? require('../assets/arrow.png')
                    : require('../assets/go.png')}})]),_c('Transition',{attrs:{"name":"slide-fade"}},[(subItem.children.length > 0 && subItem.isShow)?_c('div',_vm._l((subItem.children),function(lastItem){return _c('div',{key:lastItem._id.$id,on:{"click":function($event){$event.stopPropagation();return _vm.goDetail(lastItem)}}},[_c('div',{staticClass:"last-article-item"},[_c('div',{staticClass:"sub-article-title"},[_vm._v(_vm._s(lastItem.name))]),_c('img',{class:{ rightIcon: lastItem.children.length === 0 },attrs:{"src":lastItem.children.length > 0
                          ? require('../assets/arrow.png')
                          : require('../assets/go.png')}})]),_c('Transition',{attrs:{"name":"slide-fade"}},[(lastItem.children.length > 0 && lastItem.isShow)?_c('div',_vm._l((lastItem.children),function(last1Item){return _c('div',{key:last1Item._id.$id,on:{"click":function($event){$event.stopPropagation();return _vm.goDetail(last1Item)}}},[_c('div',{staticClass:"last-article-1-item"},[_c('div',{staticClass:"sub-article-title"},[_vm._v(_vm._s(last1Item.name))]),_c('img',{class:{ rightIcon: last1Item.children.length === 0 },attrs:{"src":last1Item.children.length > 0
                          ? require('../assets/arrow.png')
                          : require('../assets/go.png')}})])])}),0):_vm._e()])],1)}),0):_vm._e()])],1)}),0)]):_vm._e()])],1)}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLaunchWeapp),expression:"showLaunchWeapp"}],staticClass:"launch-weapp"},[_c('div',{staticClass:"launch-weapp-content"},[_vm._m(0),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","margin-top":"20px","align-items":"center","justify-content":"center"}},[_c('p',{staticClass:"launch-weapp-btn-cancel",on:{"click":function($event){_vm.showLaunchWeapp=false}}},[_vm._v("取消")]),_c('div',{staticStyle:{"width":"20px"}}),_c('div',[_c('wx-open-launch-weapp',{attrs:{"id":"launch-btn","username":"gh_a40f157eb4ae","path":_vm.sectionInfo.content}},[_c('script',{attrs:{"type":"text/wxtag-template"}},[_vm._v(" <style> .weapp-btn { cursor: pointer; text-align: center; line-height: 42px; width: 120px; background: #092E7F; height: 42px; border-radius: 6px 6px 6px 6px; font-size: 16px; font-weight: 600; color: #FFFFFF; } </style> <div class=\"weapp-btn\">继续</div> ")])])],1)])])]),_c('el-dialog',{attrs:{"visible":_vm.showExchangeProjectDialog,"width":"100%","modal":true,"show-close":false,"modal-append-to-body":true,"custom-class":"showCustomDialogStyle"},on:{"update:visible":function($event){_vm.showExchangeProjectDialog=$event}}},[_c('div',{staticClass:"dialog-container"},[_c('el-select',{staticClass:"project-select",attrs:{"popper-append-to-body":false},model:{value:(_vm.select_project_code),callback:function ($$v) {_vm.select_project_code=$$v},expression:"select_project_code"}},_vm._l((_vm.projects),function(project,index){return _c('el-option',{key:index,attrs:{"label":project.project_name,"value":project.project_code}})}),1),_c('div',{staticStyle:{"width":"100%","text-align":"left","margin-top":"20px"}},[_c('div',{staticClass:"confirm-btn",on:{"click":_vm.confirmSelectProject}},[_vm._v("确认")])])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('p',{staticStyle:{"color":"#333333","font-size":"18px","font-weight":"500"}},[_vm._v("即将打开小程序")])])
}]

export { render, staticRenderFns }